<template>
  <div class="space-empty dark">
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="insert-h">
          <div class="attr">空间名称</div>
          <div class="val">
            <el-input placeholder="请输入空间名称"
                      v-model="space.name"></el-input>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="insert-h">
          <div class="attr">空间头像</div>
          <div class="val">
            <div class="demo-block left">
                <el-upload class="avatar-uploader"
                          action="#"
                          :show-file-list="false"
                          :http-request="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload">
                  <img v-if="space.pic && space.pic != '-1'"
                          :src="'/file/'+ space.pic"
                          class="avatar" />
                  <div class="avatar-uploader-icon icomoon-plus" v-if="!space.pic || space.pic == '-1'"></div>
                </el-upload>
                <el-button class="icomoon-bin"
                        v-show="space.pic && space.pic != '-1'"
                        @click="space.pic = '-1'"
                        title="clear picture"
                        style="margin-left:10px"></el-button>
              </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="insert-h">
          <div class="attr">父空间<p>（非必填）</p></div>
          <div class="val">
            <!-- <el-input style="display: block; width: 100%"
                        v-model="space.pName"
                        placeholder="选择父空间"
                        readonly="readonly"
                        class="my-select" 
                        v-if="space.pid != '-1'"/> -->
            <el-dropdown style="width: 100%"
                         trigger="click"
                         placement="bottom-start"
                         hide-on-click>
              <el-input style="display: block; width: 100%"
                        v-model="space.pName"
                        placeholder="选择父空间"
                        readonly="readonly"
                        class="my-select" />
              <template #dropdown>
                <el-dropdown-menu style="width: 720px; max-height: 200px">
                  <el-tree :data="spaceList"
                           hide-checkbox
                           :highlight-current="true"
                           :default-expand-all="true"
                           :props="defaultProps"
                           @node-click="selectNodeClick" />
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="insert-h">
          <div class="attr">空间描述<p>（非必填）</p>
          </div>
          <div class="val">
            <el-input type="textarea"
                      placeholder="请输入文本"
                      v-model="space.description"
                      :rows="4"></el-input>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="line align-center">
          <el-button @click="goLastPage()">取消</el-button>
          <el-button type="primary"
                     @click="submit()">创建空间</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import api from 'network/api'
import { ElMessage } from 'element-plus'
import _ from 'underscore'
export default {
  name: "SpaceEmpty",
  setup () {
    const $router = useRouter(), $route = useRoute();
    const internalInstance = getCurrentInstance()
    let parentObj = $route.params;
    let space = ref({
      name: "",
      pic: "",
      pid: parentObj.pid ? parentObj.pid : '-1',
      pName: parentObj.pname? parentObj.pname: '',
      isPublish: ref(0),
      description: ""
    });
    
    let spaceList = ref([]), recordList = ref([]);

    const defaultProps = reactive({
      children: 'children',
        label: 'label',
    })

    const goLastPage = () => {
      $router.go(-1);
    };

    const submit = () => {
      if(!space.value.pid) {
        space.value.pid = '-1';
      }
      api.sp.addSpaceInfo(space.value).then((res) => {
        if (res) {
          ElMessage.success('操作成功');
          goLastPage();
        }
      })
    };

    const getSpaceList = () => {
      parentObj = $route.params;
      space.value.pid = parentObj.pid;
      space.value.pname = parentObj.pname;
      spaceList.value = [];
      api.sp.getAllSpaces().then((res) => {
       if(parentObj.pid && parentObj.pid != '-1') {
         res = _.filter(res, (v) => {
          return v.id == parentObj.pid;
        })
       }

        recordList.value = res;
        _.each(recordList.value, (v) => {
          v.label = v.name;
        })
        let root = _.filter(recordList.value, (v) => {
          return '-1' == v.pid;
        })
        if (root && root.length > 0) {
          _.each(root, (v) => {
            v = getChildren(v);
          })
        }
        spaceList.value = root;
      })
    };

    const getChildren = (obj) => {
      let children = _.filter(recordList.value, (v) => {
        return obj.id == v.pid;
      })
      if (children && children.length > 0) {
        obj.children = children;
        _.each(obj.children, (v) => {
          v = getChildren(v);
        })
      }
      return obj;
    };

    const selectNodeClick = (item) => {
      space.value.pid = item.id;
      space.value.pName = item.name;
    };

    const handleAvatarSuccess = (data) => {
      api.file.uploadFile(data.file).then((res) => {
        space.value.pic = res.id;
      })
    };

    const beforeAvatarUpload = (file) => {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        ElMessage.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 2MB!')
      }
      return isJPG && isLt2M
    };
    
    // onMounted(() => {
    //   getSpaceList();
    // })

    return {
      defaultProps,
      space,
      parentObj,
      spaceList,
      recordList,
      goLastPage,
      submit,
      getSpaceList,
      selectNodeClick,
      handleAvatarSuccess,
      beforeAvatarUpload
    }
  },
  mounted() { 
    this.getSpaceList();
  },
  // deactivated() {
  //   this.space = {
  //     name: "",
  //     pic: "",
  //     pid: this.parentObj.pid ? this.parentObj.pid : '-1',
  //     pName: this.parentObj.pname? this.parentObj.pname: '',
  //     isPublish: ref(0),
  //     description: ""
  //   };
  //   this.spaceList = [];
  // },
}
</script>

<style lang="scss" scoped>
.space-empty {
  width: 100%;
  float: left;
  max-width: 720px;
}
</style>