<template>
  <div class="space-mgt">
    <el-scrollbar>
      <div class="frame common-frame dark">
        <div class="line" style="padding:24px 0 16px">
          <div class="right">
            <el-button type="primary" @click="spaceAdd()">新增空间</el-button>
          </div>
        </div>
        <el-table
          ref="table"
          class="space-table folder-table"
          :data="tableData"
          row-key="id"
          :show-header="false"
        >
          <el-table-column>
            <template #default="scope">
              <div class="space-name">
                <i
                  class="icomoon-bookmark"
                  v-if="scope.row.type == 2"
                  style="width: 30px;"
                ></i>
                <i
                  class="icomoon-folder-open"
                  v-if="scope.row.type == 1"
                  style="width: 30px;"
                ></i>
                <img :src="scope.row.picture" alt="封面" />
                <span @click="detail(scope.row, 2)"> {{ scope.row.name }}</span>
                <p class="space-lock icomoon-lock"></p>
                <b class="space-owner" v-if="scope.row.roleName">{{
                  scope.row.roleName
                }}</b>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="48">
            <template #default="scope">
              <div
                class="space-cog icomoon-cog"
                @click="detail(scope.row, 1)"
              ></div>
            </template>
          </el-table-column>
          <el-table-column width="72">
            <template #default="scope">
              <div class="space-label">
                <i class="icomoon-folder-open"></i>
                {{ scope.row.spaceCount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="72">
            <template #default="scope">
              <div class="space-label">
                <i class="icomoon-bookmark"></i>
                {{ scope.row.prjCount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="72">
            <template #default="scope">
              <div class="space-label">
                <i class="icomoon-users"></i>
                {{ scope.row.memberCount }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "underscore";
import SpaceEmpty from "./childComps/SpaceEmpty";
import { useMutataions } from "components/util/mapMutations";

export default {
  name: "SpaceMgt",
  components: {
    SpaceEmpty
  },
  setup(props, context) {
    const $router = useRouter();
    const $store = useStore();
    const storeMutations = useMutataions("Global", ["setSpOrPrj"]);

    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $message =
      getCurrentInstance().appContext.config.globalProperties.$message;
    //定义数组赋值 ref([])将其设置为变量；或者reactive([]),通过push；或者reactive({data: []})
    let tableData = ref([]),
      recordList = ref([]);
    let memberList = ref([]);

    const table = ref(null);

    const defaultSpacePic = require("assets/images/frame/default-space.svg");
    const defaultPrjPic = require("assets/images/demo/project.jpg");

    const spaceDetail = item => {
      if (item.type == 1) $router.push("/Main/SpaceDetail");
      else if (item.type == 2) $router.push("/Main/Preview");
    };

    const setting = item => {
      if (item.type == 1) $router.push("/Main/Setting");
      else if (item.type == 2) $router.push("/Main/PrjSetting");
    };

    const getChildren = obj => {
      let children = _.filter(recordList.value, v => {
        return obj.id == v.pid;
      });
      if (children && children.length > 0) {
        obj.children = children;
        _.each(obj.children, v => {
          v = getChildren(v);
        });
      }
      return obj;
    };

    const spaceAdd = () => {
      $router.push({ name: "SpaceAdd", params: { pid: "-1" } });
    };

    const getDataFn = () => {
      $api.sp.selectAll().then(res => {
        _.each(res, v => {
          if (v.pic && v.pic != "-1") v.picture = "/file/" + v.pic;
          else if (v.type == 1) v.picture = defaultSpacePic;
          else v.picture = defaultPrjPic;
          v.roleName = getRoleNameById(v.roleId);
        });
        recordList.value = res;
        let root = _.filter(res, v => {
          return "-1" == v.pid;
        });
        if (root && root.length > 0) {
          _.each(root, v => {
            v = getChildren(v);
          });
        }
        tableData.value = root;
        if (tableData.length > 0) {
          $nextTick(() => {
            table.value.toggleRowExpansion(tableData.value[0], true);
          });
        }
      });
    };

    const getRoleNameById = (id) => {
      let result = "";
      switch (parseInt(id)) {
        case 1:
          result = "拥有者";
          break;
        case 2:
          result = "管理员";
          break;
        case 3:
          result = "配置人员";
          break;
        case 4:
          result = "开发人员";
          break;
        case 5:
          result = "游客";
          break;
        default:
          break;
      }
      return result;
    };

    const detail = (item, type) => {
      if (item.type == 1) {
        storeData(item, () => {
          $api.sp.setCurrentSpace({ id: item.id }).then(res => {
            storeMutations.setSpOrPrj('space');
            if (1 == type) setting(item);
            else if (2 == type) spaceDetail(item);
          });
        });
      } else if (item.type == 2) {
        storeData(item, () => {
          $api.sp.setCurrentPrjKey({ id: item.id }).then(res => {
            storeMutations.setSpOrPrj('project');
            if (1 == type) setting(item);
            else if (2 == type) spaceDetail(item);
          });
        });
      }
    };

    const storeData = (item, callback) => {
      if (item.type == 1) {
        getAllMember(item.id).then(() => {
          storeMember(() => {
            if (callback) callback();
          });
        });
      } else if (item.type == 2) {
        getAllPrjMember(item.id).then(() => {
          storeMember(() => {
            if (callback) callback();
          });
        });
      }
    };

    const getAllMember = (spaceId) => {
      return new Promise((res, rej) => {
        $api.sp.getAllMembers({ spaceId: spaceId }).then(data => {
          memberList.value = data;
          $store.commit("setSpaceMember", { id: spaceId, list: data });
          res();
        });
      });
    };

    const getAllPrjMember = (prjId) => {
      return new Promise((res, rej) => {
        $api.sp.getAllPrjMembers({ prjId: prjId }).then(data => {
          memberList.value = data;
          $store.commit("setProjectMember", { id: prjId, list: data });
          res();
        });
      });
    };

    const storeMember = (callback) => {
      let admin = _.find($store.state.user.roleInfos, v=> {
        return v.id == '-2';
      })
      if(admin) {
        if (callback) callback();
        return;
      }
      if (memberList.value && memberList.value.length > 0) {
        let userId = $store.state.user.userInfo.id;
        let member = memberList.value.filter(v => {
          return v.userId === userId;
        });
        if (member && member.length > 0) {
          let date = new Date();
          let dateFormat =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            "-" +
            (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
          if (
            Date.parse(member[0].expireDate) >=
              Date.parse(new Date(dateFormat)) ||
            member[0].expireDate == null
          ) {
            if (callback) callback();
          } else {
            $message({
              type: "error",
              message: "该用户已经过期，无权限访问"
            });
            return;
          }
        } else {
          $message({
            type: "error",
            message: "该用户不在项目内，无权限访问"
          });
          return;
        }
      }
    };

    onMounted(() => {
      getDataFn();
    })

    return {
      tableData,
      memberList,
      recordList,
      defaultSpacePic,
      defaultPrjPic,
      table,

      spaceAdd,
      spaceDetail,
      setting,
      getChildren,
      detail
    };
  },
};
</script>

<style lang="scss" scoped>
.space-mgt {
  width: 100%;
  height: 100%;
}

.space-table {
  width: 100%;

  .space-label {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;

    i {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      margin-right: 4px;
      color: inherit;
    }
  }

  .space-cog {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    cursor: pointer;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      color: rgb(30, 170, 255);
    }
  }

  .space-name {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    i {
      font-style: normal;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 12px;
    }

    span {
      max-width: 320px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 12px;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: rgb(30, 170, 255);
      }
    }

    p {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      cursor: pointer;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.4);
      margin: 0 12px 0 0;
    }

    b {
      height: 24px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      font-size: 14px;
      font-weight: normal;
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>
<style lang="scss">
.folder-table {
  .el-table__expand-icon {
    width:18px !important;
    height:18px !important;
  }
}
</style>
