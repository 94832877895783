import { mapMutations, createNamespacedHelpers } from 'vuex';
import {useMutationMapper} from './useMapper'

export function useMutataions(moduleName, mapper) {
    let mapperFn = mapMutations;
    
	// 如果使用模块化，则使用vuex提供的createNamespacedHelpers方法找到对应模块的mapActions方法
    if (moduleName.length > 0) {
        mapperFn = createNamespacedHelpers(moduleName).mapMutations
    }

    return useMutationMapper(mapper, mapperFn)
}